
<template>
  <div class="activity">
    <div class="content" v-if="info.promotionStatus===2">
      <!--头图-->
      <img class="headImg" :src="info.marketingHeadPictureUrl" alt="">

      <!--领券区-->
      <div class="getCouponBox">
        <img src="../assets/coupon/couponHead.png" alt="" class="headImg">
        <div class="bodyImg">
          <!--默认进来状态 0 -->
          <div v-if="graspStatus===0">
            <div class="tit">- 新石器无人车 -</div>
            <div class="cont" v-if="info.marketingPaperworkContent">{{info.marketingPaperworkContent}}</div>
            <!-- <div class="cont ">{{info.marketingPaperworkContent}}sacdsregtrhv山东潍坊v但是v为个人被告vd</div> -->
            <div class="sub">（优惠券）</div>
            <div class="btn" @click="collection">一键领取</div>
          </div>
          <!--领取成功 1-->
          <div v-if="graspStatus===1">
            <div class="tit tit2">- 领取成功 -</div>
            <div class="couponBox" v-for="(item,index) in couponList" :key='index' v-if="open || (!open && index < 3)">
              <div class="left">
                <img class="bg" src="../assets/coupon/coupon.png">
                <div class="num" v-if="item.type===0">￥<span>{{item.discountPrice}}</span></div>
                <div class="num" v-else><span>{{item.discountPrice * 10}}</span>折</div>
                <div class="over">{{item.overPrice > 0 ? '满'+item.overPrice+'可用' : '无门槛'}}</div>
              </div>
              <div class="right">{{item.name}}</div>
            </div>
            <div v-if="!open && couponList.length>3">
              <img class="trans" src="../assets/coupon/trans.png">
              <div class="open" @click="openBox">查看全部{{couponList.length}}张券 <img src="../assets/coupon/openCouponList.png"></div>
            </div>
            <div class="btn" @click="toIndex">立即使用</div>
          </div>
          <!--已领取过 2-->
          <div v-if="graspStatus===2">
            <div class="tit tit2">- 已领取过 -</div>
            <div class="couponBox" v-for="item in couponList" :key='item' v-if="open || (!open && index < 3)">
              <div class="left">
                <img class="bg" src="../assets/coupon/coupon.png">
                <div class="num" v-if="item.type===0">￥<span>{{item.discountPrice}}</span></div>
                <div class="num" v-else><span>{{item.discountPrice * 10}}</span>折</div>
                <div class="over">{{item.overPrice > 0 ? '满'+item.overPrice+'可用' : '无门槛'}}</div>
              </div>
              <div class="right">{{item.name}}</div>
            </div>
            <div v-if="!open && couponList.length>3">
              <img class="trans" src="../assets/coupon/trans.png">
              <div class="open" @click="openBox">查看全部{{couponList.length}}张券 <img src="../assets/coupon/openCouponList.png"></div>
            </div>
            <div class="btn" @click="toIndex">立即使用</div>
          </div>
          <!--领取失败  3 不符合活动规则 4 领取失败-->
          <div v-if="graspStatus===3 || graspStatus===4">
            <div class="tit tit2">- 领取失败 -</div>
            <div class="cont cont2">{{graspMsg || '不符合活动规则，请查看活动规则'}}</div>
            <div class="btn" @click="reset">返回重试</div>
          </div>
        </div>
        <img class="bg" src="../assets/coupon/bg.png" alt="">
      </div>
      <!--活动规则-->
      <div class="rule">
        <div class="tit">活动规则</div>
        <div v-html="info.promotionRules" style="font-size: 0.28rem;"></div>
        <!-- <rich-text nodes="{{info.promotionRules}}"></rich-text> -->
      </div>
    </div>

    <!--活动没开始-->
    <div class="content2" v-else-if="info.promotionStatus===1">
      <img src="../assets/coupon/pre.png" alt="" class="img">
      <div class="txt">活动尚未开始</div>
      <div class="btn" @click="toIndex">前往APP首页</div>
    </div>

    <!--活动已结束-->
    <div class="content2" v-else-if="info.promotionStatus===3">
      <img src="../assets/coupon/nex.png" alt="" class="img">
      <div class="txt">活动已结束</div>
      <div class="btn" @click="toIndex">前往APP首页</div>
    </div>
    <!-- 弹窗手机号 -->
    <div class="modal-bg" v-if="showModal">
      <!-- 输入手机号 -->
      <div class="modal" v-if="showPhone">
        <div class="top">
          <p class="p1">登录/注册享优惠</p>
          <p class="p2">同意<span style="color: #FF613E;" @click="toAgreement">《新石器无人车服务协议》</span></p>
        </div>
        <div class="input-box">
          <div class="phone">
            <span class="p3">+86</span>
            <span class="p4"></span>
            <input 
              class="phone-input" 
              type="tel" 
              name="tel" 
              placeholder="输入手机号" 
              @keyup="changePhone($event)" 
              @change="changePhone($event)" 
              placeholder-style="font-size: 0.36rem; color: #A7A7A7;"
              ref="phoneInput"
            >
          </div>
          <div :class="phoneNumber === '' ? 'code' : 'code code-active'"   @click="getCode">获取验证码</div>
        </div>
      </div>
      <!-- 输入验证码 -->
      <div class="modal" v-else>
        <div class="top">
          <p class="p1">请输入验证码</p>
          <p class="p2" style="color: #222;">验证码已通过短信发送至 <span>{{userPhone}}</span></p>
        </div>
        <div class="content3">
          <div class="code">
            <input
              class="hidden-input"
              maxlength="6"
              type="tel"
              v-model="code" 
              @keyup.enter="handleInput"
              @input="handleInput"
              ref="codeInput"
            />
            <div class="code-items">
              <!-- <div class="splitLine" v-if="code.split('')[0]"></div> -->
              <div class="code-item">{{code.split('')[0]}}</div>
              <div class="code-item">{{code.split('')[1]}}</div>
              <div class="code-item">{{code.split('')[2]}}</div>
              <div class="code-item">{{code.split('')[3]}}</div>
              <div class="code-item">{{code.split('')[4]}}</div>
              <div class="code-item">{{code.split('')[5]}}</div>
            </div>
          </div>
          <div :class="seconds < '60' ? 'next-btn disabled' : 'next-btn'" @click='getCode' v-if="code.length < 6">
            重新获取({{seconds}})
          </div>
          <div class="next-btn" @click='handleNextClick' v-else>
            登录/注册
          </div>
        </div>
      </div>
      <img src="../assets/coupon/close.png" alt="" class="close" @click="closeModal" v-show="showClose">
    </div>
  </div>
 
</template>

<script>
  import { getPromotionInfo, graspCoupon, getVerificationCode, verifyCode} from "@/api/api";
  import Message from "vue-m-message";

  export default {
    name: "activityCoupon",
    components: {},
    data() {
      return {
        id:null,//活动ID
        status:null,
        userInfo:{},
        info:{},//活动相关内容
        graspStatus:0,//1 领取成功 2 已参与过活动 3 不符合活动规则 4 领取失败
        graspMsg:'',//领取结果的文案
        couponList:[],
        open:false,//是否展开优惠券
        showModal: false, // 控制手机号弹窗
        showPhone: true, // 手机号显示
        phoneNumber: '', //手机号
        seconds: '60', // 倒计时60s
        timer: null, // 计时器
        code: '', // 验证码
        userPhone: '', // 显示的手机号
        docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
        showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
        showClose:true  //显示或者隐藏footer
      };
    },

    created: function () {
    },

    watch: {
      //监听显示高度
      showHeight: function() {
        if(this.docmHeight > this.showHeight){
          //隐藏
          this.showClose = false
        }else{
          //显示
          this.showClose = true
        }
      }
    },

    mounted: function () {
      this.getInfo()
      //监听事件
      window.onresize = ()=>{
        return(()=>{
            this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
        })()
      }
    },

    methods: {
      //  获取活动信息
      getInfo() {
        let formData = new FormData()
        formData.append("id", this.$route.query.id)
        formData.append("token", '')
        getPromotionInfo(formData).then((data)=>{
          this.info = data
          document.title = data.promotionTitle
        })
      },

      // 领取活动券
      getCoupon(data){
        let formData = new FormData()
        formData.append("promotionId", this.$route.query.id)
        formData.append("token", data.token)
        formData.append("type", 0)
        graspCoupon(formData).then((res)=>{
          this.info.promotionStatus = res.promotionStatus;
          this.graspStatus = res.graspStatus;
          this.graspMsg = res.graspMsg;
          this.couponList = res.couponList;
        })
      },

      //  输入手机号
      changePhone(e) {
        this.phoneNumber = e.target.value 
        if(this.phoneNumber.length === 11) {
          this.$refs.phoneInput.blur() 
        }
      },

      // 一键领取
      collection() {
        this.showModal = true;
      },

      // 点击获取验证码
      getCode() {
        // this.showPhone = false;
        if (this.seconds < '60') return;

        const testPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (this.phoneNumber === '' || !this.phoneNumber.match(testPhone)) {
          Message({
            title: '请输入正确的手机号',
            type: "warning",
            collapsable: false,
          });
          return;
        }

        getVerificationCode({
          encryptedData: this.phoneNumber
        }).then((data)=>{
          this.showPhone = false;
          this.userPhone = data.phone
          this.timer = setInterval(()=>{
            this.seconds--;
            if(this.seconds == 0) {
              clearInterval(this.timer)
              this.seconds = 60
            }
          },1000)
        })
      }, 

      // 关闭弹窗
      closeModal() {
        this.showModal = false;
        this.showPhone = true;
        clearInterval(this.timer)
      },

      // 前往APP首页
      toIndex() {
        this.$router.push({
          path: "downloadApp",
          query: {
            type: 7
          }
        });
      },

      // 打开优惠券
      openBox(){
        this.open = true;
      },

      // 领取失败返回上一页面
      reset(){
        this.graspStatus = 0;
      },

      // 输入验证码
      handleInput(e) {
        this.code = e.target.value;
        if(this.code.length === 6) {
          this.$refs.codeInput.blur()
        }
      },

      // 点击登录
      handleNextClick() {
        verifyCode({
          phone: this.userPhone,
          code: this.code
        }).then((data)=>{
          this.showModal = false;
          this.showPhone = true;
          this.getCoupon(data)
        })
      },

      //  跳转无人车协议
      toAgreement() {
        this.$router.push({
          path: "userAgreement",
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  img{
    display: block;
  }
  .activity {
    width: 100%;
    .content {
      background: #FADAAF;
      height: 100%;
      padding-bottom: 1rem;
      .headImg{
        width: 7.50rem;
        height: 8.40rem;
      }
       .getCouponBox{
        /*width: 710rem;*/
        margin: -2rem auto 0;
        z-index: 2;
        position: relative;
        text-align: center;
        .headImg{
          width: 100%;
          height: 0.5rem;
          margin: 0 auto;
        }
        .bodyImg{
          min-height: 0.3rem;
          // margin: -0.4rem auto -1.70rem;
          margin: -0.3rem auto -1.2rem;
          padding: 0 0.50rem;
          word-break:break-all;
          overflow: hidden;
          background: url("../assets/coupon/letter.png") no-repeat;
          background-size: 100% 100%;

        }
        .bg{
          width: 100%;
          height: 5.11rem;
          position: absolute;
          left: 0;
          z-index: -1;
        }
        .btn{
          position: absolute;
          z-index: 2;
          width: 5.70rem;
          height: 1.40rem;
          line-height: 1.28rem;
          left: 0.90rem;
          bottom: -3.1rem;
          // bottom: 0.5rem;
          font-weight: bold;
          font-size: 0.44rem;
          color: #C9301B;
          background: url("../assets/coupon/btn.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
        }
        .trans{
          position: absolute;
          z-index: 2;
          width: 6.20rem;
          height: 1rem;
          bottom: 3.42rem;
          left: 0.65rem;
        }
        .open{
          font-size: 0.28rem;
          color: #F94141;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          position: relative;

          img{
            width: 0.32rem;
            height: 0.32rem;
            margin-left: 0.08rem;
          }
        }

        .tit{
          font-size: 0.36rem;
          color: #F94141;
          text-align: center;
          font-weight: bold;
          margin-top: 0.46rem;

          &.tit2{
            font-size: 0.52rem;
            margin: 0.20rem 0;
          }
        }
        .cont{
          font-size: 0.60rem;
          color: #F94141;
          margin-top: 0.20rem;
          text-align: center;
          font-weight: bold;

          &.cont2{
            font-size: 0.34rem;
            color: #222;
            margin-bottom: 0.40rem;
          }
        }
        .sub{
          font-weight: bold;
          font-size: 0.30rem;
          color: #F94141;
          text-align: center;
          margin-top: 0.16rem;
        }
        .couponBox{
          margin: 0 0.36re,;
          padding: 0.2rem 0.30rem;
          color: #FFF;
          display: flex;
          position: relative;
          z-index: 1;

          .left{
            width: 1.40rem;
            margin-right: 0.40rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .num{
              font-size: 0.32rem;
              font-weight: bold;

              span{
                font-size: 0.50rem;
              }
            }
            .over{
              font-size: 0.22rem;
            }
          }
          .right{
            width: 100%;
            font-size: 0.32rem;
            font-weight: bold;
            max-width: 3.37rem;
            height: 1.14rem;
            line-height: 1.14rem;
            text-align: center;
            overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
          }
          .bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }
        }
      }
      .rule{
        background: #FFF;
        box-shadow: 0 0.06rem 0.08rem 0.04rem rgba(114,4,4,0.10);
        border-radius: 0.16rem;
        width: 7.10rem;
        padding: 0.36rem 0.40rem;
        margin: 5.36rem auto 0;
        display: block;

        .tit{
          font-size: 0.34rem;
          color: #222;
          text-align: center;
          margin-bottom: 0.32rem;
          font-weight: bold;
        }
      }
    }
    .content2{
      padding-top: 2.80rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img{
        width: 3.44rem;
        height: 3.44rem;
      }
      .txt{
        font-size: 0.32rem;
        color: #222;
        font-weight: bold;
      }
      .btn{
        width: 4.70rem;
        height: 0.88rem;
        line-height: 0.88rem;
        text-align: center;
        background: #F6C24A;
        border-radius: 0.54rem;
        font-size: 0.32rem;
        color: #222;
        font-weight: bold;
        margin-top: 1.20rem;
      }
    }
    .modal-bg {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.60);
      .modal {
        position: fixed;
        left: 50%;
        top: 3.33rem;
        transform: translate(-50%, 0);
        width: 6.3rem;
        height: 5.12rem;
        background: #FFFFFF;
        border-radius: 0.2rem;
        .top {
          width: 100%;
          height: 2.2rem;
          background-image: linear-gradient(180deg, #FDF2D7 0%, #FFFFFF 100%);
          border-radius: 0.2rem 0.2rem 0 0;
          padding: 0.48rem 0 0 0.4rem;
          box-sizing: border-box;
          .p1 {
            font-size: 0.48rem;
            color: #222222;
          }
          .p2 {
            font-size: 0.28rem;
            color: #000000;
            padding-top: 0.16rem;
          }
        }
        .input-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .phone {
            width: 5.5rem;
            height: 0.96rem;
            background: #F4F5F8;
            border-radius: 0.58rem;
            display: flex;
            align-items: center;
            .p3{
              font-size: 0.4rem;
              color: #222222;
              padding-left: 0.32rem;
              padding-right: 0.28rem;
            }
            .p4{
              width: 0.02rem;
              height: 0.4rem;
              background: #E1E1E1;
              margin-right: 0.32rem;
            }
            .phone-input {
              width: 3.62rem;
              height: 100%;
              background: #F4F5F8;
              font-size: 0.4rem;
              color: #222222;
            }
          }
          .code {
            width: 5.5rem;
            height: 0.96rem;
            background-image: linear-gradient(90deg, #FFEEC5 0%, #FCE7B7 100%);
            border-radius: 0.48rem;
            font-size: 0.32rem;
            color: #A7A7A7;
            line-height: 0.96rem;
            text-align: center;
            margin-top: 0.36rem;
            font-weight: bold;
          }
          .code-active {
            background-image: linear-gradient(90deg, #FFD36C 0%, #F6C24A 100%);
            color: #222222;
          }
        }
        .content3 {
          width: 5.5rem;
          margin: 0 auto;
          .code {
            position: relative;
            overflow: hidden;
            width: 5rem;
            margin: 0 auto;
            .hidden-input {
              position: absolute;
              left: 0%;
              z-index: 0;
              width: 100%;
              height: 1.12rem;
              background: transparent;
              color: transparent;
              opacity: 0;
            }

            .code-items {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .code-item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 0.76rem;
                height: 0.96rem;
                font-size: 0.4rem;
                font-weight: bold;
                color: #000000;
                background: #F4F5F8;
                border-radius: 0.16rem;
                .splitLine {
                  width: 0.04rem;
                  height: 0.48rem;
                  background: #436EFF;
                }
              }
            }
          }
          .next-btn {
            width: 5.50rem;
            height: 0.96rem;
            border-radius: 0.48rem;
            font-size: 0.32rem;
            color: #222222;
            font-weight: bold;
            text-align: center;
            line-height: 0.96rem;
            background-image: linear-gradient(90deg, #FFD36C 0%, #F6C24A 100%);
            margin-top: 0.36rem;
          }
          .disabled {
            background-image: linear-gradient(90deg, #FFEEC5 0%, #FCE7B7 100%);
            color: #A7A7A7;
          }
        }
      }
      .close {
        position: fixed;
        left: 50%;
        bottom: 2.41rem;
        transform: translate(-50%, 0);
        width: 0.8rem;
        height: 0.8rem;
        display: block;
      }
    }
    
  }
</style>